<template>
    <div>
        <!-- <div class="runbox">
            <b-container fluid>
                <b-row style="padding:10px;">
                    <b-col style="margin-left:10px;">
                        <b-badge variant="danger">NOTICE</b-badge> 
                        <strong> ประกาศ !!! ระบบปิดชั่วคราววันที่ 20 มิถุนายน 2563</strong>
                        <b-button variant="link" size="sm" style="color:white;">x</b-button>
                    </b-col>
                    <b-col style="flex: 0 0 150px;">
                        <div class="vl" style="position: absolute;top: 0px;left: 0px;"></div>
                        <b-form-select v-model="$i18n.locale" :options="lg_options" size="sm" class="lg_select"></b-form-select>
                    </b-col>
                </b-row>
            </b-container>
        </div> -->
        <b-navbar toggleable="lg" type="dark" class="header" fixed="top">
            <div class="header_box">
            </div>
            <b-navbar-brand href="#" :to="{ name: 'Home' }">
                <img src="@/assets/logo.png" alt="logo" style="margin-left:20px;float:left;" class="responsive">
                <!-- <div style="margin-top:10px;">
                </div> -->
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse" style="color:#016FB7!important;">
                <i class="fas fa-bars"></i>
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto" style="font-size: 14px;margin-top:30px;padding-bottom:0px;">
                    <b-nav-item :to="{ name: 'Home' }" style="font-weight: bold;padding:0px;">
                        <div style="color:#016FB7;" v-if="$route.name == 'Home'">
                            <!-- <i class="fas fa-home"></i> {{$t('header.home')}} -->
                            <img src="@/assets/menu/icon-home.png" alt="home" height="15"> {{$t('header.home')}}
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                        <div v-else style="color: rgba(32, 66, 131, 0.52);">
                            <!-- <i class="fas fa-home"></i> {{$t('header.home')}} -->
                            <!-- <hr style="border: 1px solid;margin-top:5px;"> -->
                            <img src="@/assets/menu/icon-home2.png" alt="home" height="15"> {{$t('header.home')}}
                        </div>
                    </b-nav-item>
                    <!-- <div style="padding-right:30px;"/>
                    <b-nav-item :to="{ name: 'Map' }" style="font-weight: bold;color:#204283;">
                        <div style="color:#016FB7;" v-if="$route.name == 'Map'">
                            <i class="fas fa-map-marker"></i> แผนที่
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                        <div v-else style="color: rgba(32, 66, 131, 0.52);">
                            <i class="fas fa-map-marker"></i> แผนที่
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                    </b-nav-item> -->
                    <div style="padding-right:30px;"/>
                    <b-nav-item :to="{ name: 'Report' , params: { rid: '0'} }" style="font-weight: bold;color:#204283;">
                        <div style="color:#016FB7;" v-if="$route.name == 'Report'">
                            <img src="@/assets/menu/icon-list.png" alt="list" height="15"> {{$t('header.list')}}
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                        <div v-else style="color: rgba(32, 66, 131, 0.52);">
                            <!-- <i class="fas fa-phone-alt"></i> {{$t('contact')}} -->
                            <img src="@/assets/menu/icon-list2.png" alt="list" height="15"> {{$t('header.list')}}
                            <!-- <hr style="border: 1px solid;margin-top:5px;"> -->
                        </div>
                    </b-nav-item>
                    <div style="padding-right:30px;"/>
                    <b-nav-item :to="{ name: 'History' }" style="font-weight: bold;color:#204283;">
                        <div style="color:#016FB7;" v-if="$route.name == 'History'">
                            <i class="fa fa-table"></i> {{$t('header.history')}}
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                        <div v-else style="color: rgba(32, 66, 131, 0.52);">
                            <i class="fa fa-table"></i> {{$t('header.history')}}
                        </div>
                    </b-nav-item>
                    <div style="padding-right:30px;"/>
                    <b-nav-item :to="{ name: 'AQIinfo' }" style="font-weight: bold;color:#204283;">
                        <div style="color:#016FB7;" v-if="$route.name == 'AQIinfo'">
                            <i class="fa fa-info"></i> {{$t('header.info')}}
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                        <div v-else style="color: rgba(32, 66, 131, 0.52);">
                            <i class="fa fa-info"></i> {{$t('header.info')}}
                        </div>
                    </b-nav-item>
                    <div style="padding-right:30px;"/>
                    <b-nav-item :to="{ name: 'Download' }" style="font-weight: bold;color:#204283;">
                        <div style="color:#016FB7;" v-if="$route.name == 'Download'">
                            <img src="@/assets/menu/icon-download.png" alt="download" height="15"> {{$t('header.download')}}
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                        <div v-else style="color: rgba(32, 66, 131, 0.52);">
                            <!-- <i class="fas fa-phone-alt"></i> {{$t('contact')}} -->
                            <img src="@/assets/menu/icon-download2.png" alt="download" height="15"> {{$t('header.download')}}
                            <!-- <hr style="border: 1px solid;margin-top:5px;"> -->
                        </div>
                    </b-nav-item>
                    <div style="padding-right:30px;"/>
                    <b-nav-item :to="{ name: 'Faq' }" style="font-weight: bold;color:#204283;">
                        <div style="color:#016FB7;" v-if="$route.name == 'Faq'">
                            <i class="fa fa-question-circle"></i> {{$t('header.faq')}}
                            <hr style="border: 1px solid;margin-top:5px;">
                        </div>
                        <div v-else style="color: rgba(32, 66, 131, 0.52);">
                            <!-- <i class="fas fa-phone-alt"></i> {{$t('contact')}} -->
                             <i class="fa fa-question-circle"></i> {{$t('header.faq')}}
                            <!-- <hr style="border: 1px solid;margin-top:5px;"> -->
                        </div>
                    </b-nav-item>
                    <div style="padding-right:30px;"/>
                    <div style="margin-top:10px;" class="lg_bt" @click="switchLocale()">
                        <div v-if="$i18n.locale == 'th'">
                            <img src="@/assets/TH.png" height="28" /> <strong style="padding-top:5px;"> TH</strong>
                        </div>
                        <div v-if="$i18n.locale == 'en'">
                            <img src="@/assets/EN.png" height="28" /> <strong style="padding-top:5px;"> EN</strong>
                        </div>
                        <!-- <div class="vl" style="position: absolute;top: 0px;left: 0px;"></div>
                        <b-form-select v-model="$i18n.locale" :options="lg_options" size="sm" class="lg_select"></b-form-select> -->
                    </div>
                    <div style="padding-right:30px;"/>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { Settings } from 'luxon'


export default {
    
    name : 'Header',
    components : {
        
    },
    data () {
        return {
            lg_selected: 'th',
            lg_options: [
                { value: 'th', text: 'ไทย' },
                { value: 'en', text: 'English' },
            ]
        }
    },
    methods : {
        switchLocale(){
            if (this.$i18n.locale === 'th') {
                this.$i18n.locale = 'en'
                Settings.defaultLocale = 'en'
                Settings.locale = 'en'
            } else {
                this.$i18n.locale = 'th'
                Settings.defaultLocale = 'th'
                Settings.locale = 'th'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.lg_bt{
    cursor: pointer;
    transition: 400ms;
    transition-timing-function: ease-in-out;
    // img {
    //     transition: 400ms;
    //     transition-timing-function: ease-in-out;
    // }
}
.lg_bt:hover {
    transform: scale(1.1);
}
.lg_bt:active {
//   transform: translateY(10px);
  transform: scale(0.9);
//   img {
//       position: relative;
//       opacity: 0.5;
//       transform: translateY(-10px);
//   }
}
.navbar-dark .navbar-toggle {
    color:#016FB7!important;
}
.header_box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 15px;
    width: 100%;
    background: #016FB7;
    
}
.header {
    // position: fixed;
    // top: 10px;
    padding-top: 20px;
    background:  #ffffff;;
    color: #016FB7;
    box-shadow: 0px 5px 20px rgba(146, 146, 146, 0.219);
}
.header_title{
    text-align: left;
    font-size: max(1.2vw, 12px);
    line-height: max(1.2vw, 12px);
    display: inline-block;
    padding-left:10px;
    color: #204283;
}
.responsive{
    width: auto;
    height: 30px;
    // max-width: 100;
    // max-height: 100;
    margin: 0px;
    padding: 0px;
    // height: auto;
}
.runbox{
    // height: 50px;
    // width: 100%;
    // margin-right:100px;
    // padding: 5px;
    background-color: #016FB7;
    text-align: left;
    color: white;
    font-size: max(1vw, 10px);
}
.vl {
  border-left: 2px solid rgb(255, 255, 255);
  height: 100%;
}
.lg_select{
    // background-color: #016FB7;
    background: #016FB7 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;
    color:white;
    border-color: #016FB7;
    border-width:1px;
}
</style>